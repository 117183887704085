import React from 'react';
import './DeliverySiteBody.css';
import DeliverySiteBodyElement from './DeliverySiteBodyElement';

// render the delivery site body
const DeliverySiteBody = (props) => {
  const isFirstLoad = props.isFirstLoad;
  const theDeliverySite = props.theDeliverySite;

  // determine statuses and other info
  const isDeliverySiteDefined = theDeliverySite !== undefined;
  const unavailableTerm = 'Unavailable';
  let activityState = isDeliverySiteDefined ? theDeliverySite.activityState : undefined;
  let addressLine1 = isDeliverySiteDefined ? theDeliverySite.addressLine1 : undefined;
  const addressLine2 = isDeliverySiteDefined ? theDeliverySite.addressLine2 : undefined;
  const addressLine3 = isDeliverySiteDefined ? theDeliverySite.addressLine3 : undefined;
  if (addressLine1 === undefined && addressLine2 === undefined && addressLine3 === undefined)
    addressLine1 = unavailableTerm;
  const physicalAddressIsValid = isDeliverySiteDefined && theDeliverySite.physicalAddressValidationOutcome === 'VALID';
  const addressValidationInfo = physicalAddressIsValid ? "NZ Post verified" : "";
  const isActivityStateManagedByCatalog = isDeliverySiteDefined && theDeliverySite.isActivityStateManagedByCatalog;
  const activityStateManagement = isActivityStateManagedByCatalog ? "Activity state managed by Catalogue" : "Activity state managed by Take2";
  const isAddressManagedByCatalog = isDeliverySiteDefined && theDeliverySite.isAddressManagedByCatalog;
  const addressManagement = isAddressManagedByCatalog ? "Address managed by Catalogue" : "Address managed by Take2";
  const fullAddress = addressLine1 + ',' + addressLine2 + ',' + addressLine3;
  const addressAgentUri = process.env.REACT_APP_APP_ADDRESSAGENT_BASE || 'BUGGER:REACT_APP_APP_ADDRESSAGENT_BASE';
  const deliverySiteViewerUri = `${window.location.protocol}//${window.location.host}`;
  const deliverySiteViewerLink = deliverySiteViewerUri + '?locationCode=';
  const deliverySiteViewerLinkUsingDelSiteId = deliverySiteViewerUri + '?deliverySiteId=';
  const linkText = isDeliverySiteDefined ? "Edit" : null;
  const linkAddress = isDeliverySiteDefined
    ? addressAgentUri
    + 'addressChecker?address=' + fullAddress
    + "&cancelCallback=" + deliverySiteViewerLink + theDeliverySite.locationCode
    + "&saveCallback=" + deliverySiteViewerLinkUsingDelSiteId + theDeliverySite.id
    + "&context=This address belongs to delivery site " + theDeliverySite.locationCode + "."
    : null;

  // render either that we're loading data (if loaded for the first time), or the delivery site, or that the delivery site does not exist
  let bodyFragment = isDeliverySiteDefined
    ? <>
      <DeliverySiteBodyElement title="Location code" text1={theDeliverySite.locationCode} />
      <DeliverySiteBodyElement
        title="Address"
        text1={addressLine1}
        text2={addressLine2}
        text3={addressLine3}
        subText={addressValidationInfo} subIcon="/nz-post-icon-tight-crop-100x100.png" subIconAlt="NZ Post icon"
        secondSubText={addressManagement} secondSubIcon="/catalog-management.png" secondSubIconAlt="Address management icon"
        linkText={linkText} linkAddress={linkAddress}
      />
      <DeliverySiteBodyElement
        title="Activity state"
        text1={activityState}
        subText={activityStateManagement} subIcon="/catalog-management.png" subIconAlt="Activity state management icon"
      />
    </>
    : isFirstLoad
      ? // this is the very first load
      <DeliverySiteBodyElement title="Loading ..." className="notification" />
      : // this is a subsequent load
      <DeliverySiteBodyElement title="Delivery site does not exist." className="error" />

  // render
  return (<>
    <div className="DeliverySiteBody">
      <div className="App-wrapper DeliverySiteBody-wrapper">
        {bodyFragment}
      </div>
    </div>
  </>);
}

export default DeliverySiteBody;