// fetch the delivery site
export async function fetchDeliverySite(
  idOrLocationCode // the reference that identified this delivery site
) {
  // call the API and get the delivery site
  let result = undefined; // default result if we can't fetch anything

  // exit early if someone hasn't provided a delivery site id or a location code
  if (!idOrLocationCode || idOrLocationCode.length === 0) {
    return result;
  }

  // prepare for loading the delivery site
  const apiEndpoint = process.env.REACT_APP_API_BFF_BASE || 'BUGGER:REACT_APP_API_BFF_BASE';
  let apiUri = apiEndpoint + '/deliverySites/' + idOrLocationCode;
  console.log('Calling ' + apiUri + ' ...');
  let response = await fetch(apiUri, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    mode: 'cors' // TODO SO: ensure this is going to be a CORS call at some point
  }).then(
    response => {
      // if everything is okay, bypass the response code handling
      if (response.ok)
        return response;

      // now handle error edge cases
      switch (response.status) {
        case 404: // 404 means no delivery site was found
          console.log("Delivery site not found.");
          return undefined;
        case 409: // 409 means there is an issue with the data integrity in the API
          throw new Error('DATA INTEGRITY ERROR: ' + response.status + ': ' + response.statusText);
        default: // Other errors are not expected but UI should notify user
          throw new Error('UNEXPECTED ERROR: ' + response.status + ': ' + response.statusText);
      }
    }
    // it's important to handle errors here instead of a catch() block so that we don't swallow exceptions from actual bugs in components
  ).catch(err => {
    // TODO:MC - stop swallowing exceptions
    console.log(err.message);
  });

  if (!!response && response.ok) {
    const data = await response.json();
    result = {
      activityState: data.activityState === null ? undefined : data.activityState,
      addressLine1: data.addressLine1 === null ? undefined : data.addressLine1,
      addressLine2: data.addressLine2 === null ? undefined : data.addressLine2,
      addressLine3: data.addressLine3 === null ? undefined : data.addressLine3,
      id: data.id === null ? undefined : data.id,
      isActivityStateManagedByCatalog: data.isActivityStateManagedByCatalog,
      isAddressManagedByCatalog: data.isAddressManagedByCatalog,
      locationCode: data.locationCode,
      physicalAddressValidationOutcome: data.physicalAddressValidationOutcome
    };
    console.log("Delivery site loaded.");
  }

  // Done.
  return result;
}

// patch the delivery site
export async function patchDeliverySite(
  deliverySiteId, // the ID of the delivery site to be updated
  addressId// the new address details (NZ Post DPID as an integer)
) {
  let result = undefined; // default result if nothing is patched
 
  // exit early if required parameters are missing
  if ((!deliverySiteId || deliverySiteId.length === 0 || !addressId || addressId.length === 0)) {
    return result;
  }
 
  // prepare for patching the delivery site
  const apiEndpoint = process.env.REACT_APP_API_BFF_BASE || 'BUGGER:REACT_APP_API_BFF_BASE';
  const apiUri = apiEndpoint + '/deliverySites/' + deliverySiteId + '/physicalAddress';
  console.log('Patching ' + apiUri + ' ...');
  let response = await fetch(apiUri, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'text/plain'
    },
    body : addressId.toString(),
    mode: 'cors' // ensure CORS compliance
  }).then(
    response => {
      return responseObject(response)
    }
  ).catch(err => {
    // handle fetch errors
    console.error(err.message);
  });
 
  // Done.
  return response;
}

// create the desired response object 
async function responseObject(response)
{
  if(response === undefined)
    return undefined;


  let  status = response.status; 
  let  statusText  = response.statusText
  let  data = (status === 200 ) ? await response.json():undefined;
  return buildResponse(status,statusText, data)

  // nested function to craft the response object
  function buildResponse(status,statusText, data){

  
    const result = {
        status: status,
        statusText: statusText,
        body:
        {
          activityState: data ? data.activityState:undefined,
          addressLine1: data ? data.addressLine1:undefined,
          addressLine2: data ? data.addressLine2:undefined,
          addressLine3: data ? data.addressLine3:undefined,
          id: data ? data.id:undefined,
          isActivityStateManagedByCatalog: data ? data.isActivityStateManagedByCatalog : undefined,
          isAddressManagedByCatalog: data ? data.isAddressManagedByCatalog : undefined,
          locationCode: data ? data.locationCode : undefined,
          physicalAddressValidationOutcome:data ?  data.physicalAddressValidationOutcome : undefined
        }
      };
   return result;
  }
}

// helper function to decide if request is to perfrom a http patch 
export function patchRequest(dSiteId, addressId, contentModified){
  return (addressId && dSiteId && contentModified);
}