import React from 'react';
import './NotificationBar.css';

const NotificationBar = (props) => {
  const message = props.notificationMessage;

  // render the notification bar
  return (
    <div className=''>{message}</div>
  );
}

export default NotificationBar;