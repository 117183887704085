import React from 'react';
import './DefaultView.css';
import DeliverySiteHeader from '../components/DeliverySiteHeader';
import DeliverySiteBody from '../components/DeliverySiteBody';

const DefaultView = (props) => {
  const isFirstLoad = props.isFirstLoad;
  const loadingMessage = props.loadingMessage;
  const theDeliverySite = props.theDeliverySite;
  const locationCode = props.locationCode;
  const message = props.notificationMessage;

  // render
  return (<>
    <div className="DefaultView">
      <DeliverySiteHeader theDeliverySite={theDeliverySite} locationCode={locationCode} loadingMessage={loadingMessage} isFirstLoad={isFirstLoad} notificationMessage={message} />
      <DeliverySiteBody theDeliverySite={theDeliverySite} isFirstLoad={isFirstLoad} />
    </div>
  </>);
}

export default DefaultView;