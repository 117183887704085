import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './App.css';
import { useInterval } from './hooks';
import DefaultView from './views/DefaultView';
import EnvironmentBar from './components/EnvironmentBar';
import { fetchDeliverySite, patchDeliverySite, patchRequest } from './library/BffFuncs.js';
import { renderTimespanToAgoPhrase } from './library/DateTimeFuncs.js';

// all views that we support
const VIEWS = {
  default: "default"
};

function App() {
  // define state and defaults
 // const [dateTimeDependency, setDateTimeDependency] = useState(new Date());
  const [dateTimeLastLoaded, setDateTimeLastLoaded] = useState(new Date());
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [theDeliverySite, setTheDeliverySite] = useState(undefined);
  const [counter, setCounter] = useState(0);
  const [contentModified, setContentModified] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState(""); 
  // let message = undefined;
    
  // use the necessary parameters if it is part of the route params
  let { dSiteId } = useParams();
  let { addressId } = useParams();

  // fetch parameters from the URI
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  // if we don't have the necessary parameters as a route param then try get it 
  // from the query params
  if (!dSiteId) {
    dSiteId = urlParams.get("locationCode") || urlParams.get("deliverySiteId");
  }
  if (!addressId) {
    addressId = urlParams.get("addressId");
  }

  // enable feature which is not released for public use
  let previewMode = urlParams.get("previewmode");

  // determine the selected view from the URI
  const viewParam = urlParams.get("view");

  let renderView;
  switch (viewParam === undefined || viewParam === null ? VIEWS.default : viewParam.toLowerCase()) {
    case VIEWS.dash:
    default:
      renderView = <DefaultView dSiteId={dSiteId} theDeliverySite={theDeliverySite} loadingMessage={loadingMessage} isFirstLoad={isFirstLoad} notificationMessage={notificationMessage} />
      break;
  }
  
  // executes every seconds , reset dateTimeLastLoaded
  useInterval(() => {
    setCounter(_counter => {return _counter + 1});
    if ( counter === 59) { // 1 minute
    setDateTimeLastLoaded(new Date());
    setCounter(0);
    }
  }, 1000); // polls for updates every 1 second

  // update loadingMessage every second , resulting in refreshing the top right corner message 
  useEffect(() => {
    async function refreshMessage()
    {
      setLoadingMessage(renderTimespanToAgoPhrase(dateTimeLastLoaded, "Info refreshed "));
    }
    refreshMessage();

  },[counter,dateTimeLastLoaded])

  // fetch delivery site data 
  useEffect(() => {
    if(patchRequest(dSiteId, addressId, contentModified)) 
      return;
    setLoadingMessage("Refreshing ...");
    async function fetchTheDeliverySite() {
    await fetchDeliverySite(dSiteId)
    .then(
      loadedDeliverySite => setTheDeliverySite(loadedDeliverySite)
    )
    .finally(() => {
        setIsFirstLoad(false);
      }
      )
    .catch(
        error => console.log(error)
      );
    }
    fetchTheDeliverySite();
  }, [dateTimeLastLoaded,dSiteId,addressId,contentModified]);

  // patch Delivery site data  - devOps feature : 4917 - only available in preview mode ie if query string "previewmode=true" exist in url 
   useEffect(() => {

    if(!patchRequest(dSiteId,addressId,contentModified)) 
      return;

     // alternative for feature toggle improvement needed
    if(!(previewMode === 'true')){
      setContentModified(false)
      return;
    }

    setLoadingMessage("Refreshing ...");
    async function patchTheDeliverySite() {
    try {
     let patchResponse = await patchDeliverySite(dSiteId, addressId);
     if(patchResponse.status === 200)
      {
        setTheDeliverySite(patchResponse.body)
        setIsFirstLoad(false)
       // update message state
      }
      else if (patchResponse.status === 304)
      {
        setContentModified(false)
        // update message state
      }
      else
      {
        setContentModified(false)
        // update message state
      }
    } 
    catch (error) 
    {
      console.log(error);
    }
    finally{
     //
    }
    }
    patchTheDeliverySite();
  }, [dSiteId,addressId,contentModified, previewMode]);

  return (
    <div className="App">
      <EnvironmentBar />
      {renderView}
    </div>
  );
}

export default App;